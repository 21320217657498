import {PostEndpoint} from '../../src/lib/ajax/postEndpoint';
import {getCartSubTotal} from '../ekitTerrificHybrisController';
import {
	AddToCartResponse,
	BackendCartStructureType,
	OrderItemCreateResponse,
	OrderItemValidationStatus,
} from '../types/emksKeySystemController';

// The following endpoints can be tested using swagger:
// http://dev-storybook-dormakaba.namics-test.com/dormakabacommercewebservices/swagger/swagger-ui.html


/**
 * Save the given reorder entries into an existing head position
 * https://wiki.namics.com/pages/viewpage.action?pageId=627740333
 */
export const addOrUpdateHeadPositionToCart = PostEndpoint.create<
	{
		language: string;
		cartid: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	BackendCartStructureType,
	AddToCartResponse
>({
	url: ({ cartid, language, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/carts/${cartid}/headposition/addToCart?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getCartSubTotal.clearCache();
		},
	}),
});

/**
 * Create the given reorder entry into an existing head position
 */
export const createReorderEntryByHeadPosition = PostEndpoint.create<
	{
		language: string;
		cartid: string;
		headPosition: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	BackendCartStructureType,
	OrderItemCreateResponse
>({
	url: ({ cartid, headPosition, language, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/carts/${cartid}/headpositions/${headPosition}/create?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getCartSubTotal.clearCache();
		},
	}),
});

/**
 * Update the given reorder entry into an existing head position
 */
export const updateReorderEntryByHeadPosition = PostEndpoint.create<
			{
				language: string;
				cartid: string;
				headPosition: string;
				token: string;
				hybrisStore: string;
				user: string;
				rootB2BUnit: string;
			},
			BackendCartStructureType,
			OrderItemValidationStatus
		>({
	url: ({ cartid, headPosition, language, hybrisStore, user, rootB2BUnit }) =>
				`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/carts/${cartid}/headpositions/${headPosition}/update?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
			options: ({token}) => ({
				headerOptions: {authToken: token},
			}),
	});

/**
 * Delete the given reorder entry into an existing head position
 */
export const deleteReorderEntryByHeadPosition = PostEndpoint.create<
	{
		language: string;
		cartid: string;
		headPosition: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		idOfPosition: string;
	},
	BackendCartStructureType,
	OrderItemValidationStatus
>({
	url: ({ cartid, headPosition, idOfPosition, language, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/carts/${cartid}/headpositions/${headPosition}/delete/${idOfPosition}?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getCartSubTotal.clearCache();
		},
	}),
});
