import { GetEndpoint } from '../../src/lib/ajax/getEndpoint';
import { PostEndpoint } from '../../src/lib/ajax/postEndpoint';
import { ProductType } from '../types/emksItemListController';

// The following endpoints use the hybris rest endpoints
// GET
export const getCartSubTotal = GetEndpoint.create<
	{
		encodedContextPath: string;
	},
	{
		miniCartCount: number;
		miniCartCountSum: number;
		miniCartPrice: string;
	}
>({
	url: ({ encodedContextPath }) => `${encodedContextPath}/cart/miniCart/SUBTOTAL`,
	cacheEnabled: true,
});

export const getQuickOrderProductInfo = GetEndpoint.create<
	{
		encodedContextPath: string;
		productCode: string;
	},
	{
		productData: {};
		priceInformationData: {};
		errorMsg?: string;
		analyticsDataLayer?: {}
	}
>({
	url: ({ encodedContextPath, productCode }) => `${encodedContextPath}/quickOrder/productInfo?code=${productCode}`,
	cacheEnabled: true,
});

export const getMiniCartContent = GetEndpoint.create<
	{
		encodedContextPath: string;
		quoteId?: string;
	},
	string
>({
	url: ({ encodedContextPath, quoteId = null }) =>
		`${encodedContextPath}/cart/rollover/MiniCart${quoteId ? `?quoteId=${quoteId}` : ''}`,
	cacheEnabled: true,
});

export const getAutocompleteContent = GetEndpoint.create<
	{
		encodedContextPath: string;
		searchString: string;
	},
	{
		suggestions: { term: string };
		products: ProductType;
	}
>({
	url: ({ encodedContextPath, searchString }) =>
		`${encodedContextPath}/search/autocomplete/SearchBox?term=${encodeURI(searchString)}`,
	cacheEnabled: true,
});

export const getQuoteContent = GetEndpoint.create<
	{
		encodedContextPath: string;
		quoteId?: string;
		projectId?: string;
	},
	{
		infoLayer?: string;
		offerListLayer?: string;
	}
>({
	url: ({ encodedContextPath, projectId, quoteId }) =>
		`${encodedContextPath}/project/${projectId}/offer/${quoteId === 'all' ? 'all' : `quote/${quoteId}`}/products`,
	cacheEnabled: true,
});

// POST
export const postMergeCartOverlayForProjectContext = PostEndpoint.create<
	{
		encodedContextPath: string;
		CSRFToken: string;
	},
	{},
	{
		layer: string;
	}
>({
	url: ({ encodedContextPath }) => `${encodedContextPath}/project/overlay/merge`,
	options: ({ CSRFToken }) => ({
		headerOptions: {
			customHeaders: {
				CSRFToken: `${CSRFToken}`,
			},
		},
	}),
});

export const postAddToCart = PostEndpoint.create<
	{
		encodedContextPath: string;
		serializedForm: string;
	},
	{},
	{
		message: string;
		type: string;
	}
>({
	url: ({ encodedContextPath, serializedForm }) => `${encodedContextPath}/cart/add?${serializedForm}`,
	options: () => ({
		afterSuccess: () => {
			getCartSubTotal.clearCache();
		},
	}),
});

export const postQuickOrderAddToCart = PostEndpoint.create<
	{
		encodedContextPath: string;
		CSRFToken: string;
	},
	{},
	{
		message: string;
		type: string;
		analyticsDataLayer?: any;
	}
>({
	url: ({ encodedContextPath }) => `${encodedContextPath}/cart/addQuickOrder`,
	options: ({ CSRFToken }) => ({
		headerOptions: {
			customHeaders: {
				CSRFToken: `${CSRFToken}`,
			},
		},
		afterSuccess: () => {
			getCartSubTotal.clearCache();
		},
	}),
});
