/* eslint-disable max-len */
import { GetEndpoint } from '../../src/lib/ajax/getEndpoint';
import { PostEndpoint } from '../../src/lib/ajax/postEndpoint';
import { DownloadMifFileStatus } from '../types/emksFileUploadController';

// The following endpoints can be tested using swagger:
// TODO: To be defined

// Datastructure description SecurityCardWsDto
// https://wiki.namics.com/display/DOKA/12+-+OCC+File+Upload

export const postReorderFile = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		systemCode: string;
		headPositionCode: string;
		user: string;
		rootB2BUnit: string;
		language: string;
	},
	FormData,
	{
		fileReference: string;
	}
>({
	url: ({ hybrisStore, systemCode, headPositionCode, user, rootB2BUnit, language }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/fileupload/reorder?systemCode=${systemCode}&headPositionCode=${headPositionCode}&rootB2BUnit=${rootB2BUnit}&lang=${language}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token, defaultContentTypeJsonUTF8: false },
	}),
});

export const postExtensionFile = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		systemCode: string;
		headPositionCode: string;
		user: string;
		rootB2BUnit: string;
		language: string;
	},
	FormData,
	{
		fileReference: string;
	}
>({
	url: ({ hybrisStore, systemCode, headPositionCode, user, rootB2BUnit, language }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/fileupload/extension?systemCode=${systemCode}&headPositionCode=${headPositionCode}&rootB2BUnit=${rootB2BUnit}&lang=${language}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token, defaultContentTypeJsonUTF8: false },
	}),
});

export const postManualOrder = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		language: string;
	},
	FormData,
	/*
    This endpoint will always return an empty response
    and an error code if the authentication fails
	*/
	void
>({
	url: ({ hybrisStore, user, rootB2BUnit, language }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/users/${user}/sendmail/emksFileUploadError?rootB2BUnit=${rootB2BUnit}&lang=${language}`,
	options: ({ token }) => ({ headerOptions: { authToken: token, defaultContentTypeJsonUTF8: false } }),
});

export const postNewKeySystemFile = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		language: string;
	},
	FormData,
	{
		fileReference: string;
		successUrl: string;
	}
>({
	url: ({ hybrisStore, user, rootB2BUnit, language }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/fileupload/?rootB2BUnit=${rootB2BUnit}&lang=${language}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token, defaultContentTypeJsonUTF8: false },
	}),
});

export const getMifDownloadStatus = GetEndpoint.create<
	{
		fileReference: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		language: string;
		systemCode?: string;
	},
	DownloadMifFileStatus
>({
	url: ({ fileReference, hybrisStore, user, rootB2BUnit, language, systemCode }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/fileupload/mifStatus?fileReference=${fileReference}&lang=${language}&rootB2BUnit=${rootB2BUnit}&lang=${language}&systemCode=${systemCode}`,
	cacheEnabled: false,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

export const postNewKeySystemManualOrder = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		language: string;
	},
	{
		fileReference: string;
		personalReference: string;
		email: string;
		phone: string;
		comment: string;
		file?: File;
	},
	/*
    This endpoint will always return an empty response
    and an error code if the authentication fails
	*/
	void
>({
	url: ({ hybrisStore, user, rootB2BUnit, language }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/users/${user}/sendmail/emksFileUploadError?rootB2BUnit=${rootB2BUnit}&lang=${language}`,
	options: ({ token }) => ({ headerOptions: { authToken: token, defaultContentTypeJsonUTF8: false } }),
});

export const getCurrentUserData = GetEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		language: string;
	},
	{
		uid: string; // TODO: TBD which data can be used for Email & Phone
		email: string;
	}
>({
	url: ({ hybrisStore, user, rootB2BUnit, language }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/users/${user}?rootB2BUnit=${rootB2BUnit}&lang=${language}`,
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

export const getSecurityCardInfo = GetEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		systemCode: string;
		user: string;
		rootB2BUnit: string;
		language: string;
	},
	{
		securityCardNumber: string;
	}
>({
	url: ({ hybrisStore, systemCode, user, rootB2BUnit, language }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/fileupload/securitycardinfo/?systemCode=${systemCode}&rootB2BUnit=${rootB2BUnit}&lang=${language}`,
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});
