import {GetEndpoint} from '../../src/lib/ajax/getEndpoint';
import {PostEndpoint} from '../../src/lib/ajax/postEndpoint';
import {
	SavedItemListMergeType,
	SavedItemListOptionType,
	SavedItemListType,
	SavedListToDelete,
	SearchPageSavedItemListType,
} from '../types/emksItemListController/index';
import {DeleteEndpoint} from '../../src/lib/ajax/deleteEndpoint';
import {NewSeriesBackendType} from '../types/emksNewSeriesController';
import {getNewSeriesEntriesByHeadPosition} from '../emksNewSeriesController';
import {getCartSubTotal} from '../ekitTerrificHybrisController';
import {AddToCartResponse, BackendCartStructureType} from '../types/emksKeySystemController';

// The following endpoints can be tested using swagger:
// http://dev-storybook-dormakaba.namics-test.com/dormakabacommercewebservices/swagger/swagger-ui.html

/**
 * Return all saved Item List for the given token without result
 */
export const getUserSavedItemLists = GetEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	SavedItemListOptionType
>({
	url: ({ user, hybrisStore, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/saveditemlists/?pagination=false&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Create a new Item
 * https://wiki.namics.com/display/DOKA/03+-+OCC+Security+Settings
 */
export const addNewSavedItemList = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	{
		code: string;
		entryNumbers: Array<number>;
		headPositions: Array<string>;
		description: string;
		name: string;
		publicAccess: boolean;
	},
	/*
    This endpoint will always return an empty response
    and an error code if the update fails
  */
	{}
>({
	url: ({ hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/saveditemlists/?rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getSavedItemLists.clearCache();
			getSelectedItemListEntries.clearCache();
			getUserSavedItemLists.clearCache();
		},
	}),
});

/**
 * Return all product entries for the given Item
 * https://wiki.namics.com/pages/viewpage.action?pageId=613780327
 */
export const getSelectedItemListEntries = GetEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		savedItemListCode: string;
		rootB2BUnit: string;
	},
	SavedItemListType
>({
	url: ({ user, hybrisStore, savedItemListCode, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/saveditemlists/${savedItemListCode}?rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

/**
 * Add Entries to Saved Item List
 * https://wiki.namics.com/display/DOKA/03+-+OCC+Security+Settings
 */
export const addItemToSavedItemList = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		savedItemListCode: string;
		productCode: string;
		displayUnitQuantity: number;
		displayUnitCode: string;
		rootB2BUnit: string;
	},
	{},
	/*
    This endpoint will always return an empty response
    and an error code if the update fails
  */
	{}
>({
	url: ({ hybrisStore, user, savedItemListCode, productCode, displayUnitQuantity, displayUnitCode, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/saveditemlists/${savedItemListCode}/entries?productCode=${productCode}&displayUnitQuantity=${displayUnitQuantity}&displayUnitCode=${displayUnitCode}&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getSavedItemLists.clearCache();
			getSelectedItemListEntries.clearCache();
			getUserSavedItemLists.clearCache();
		},
	}),
});

/**
 * Copy Entries to Saved Item List
 * https://wiki.namics.com/display/DOKA/03+-+OCC+Security+Settings
 */
export const copyItemToSavedItemList = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		cartId: string;
		rootB2BUnit: string;
	},
	SavedItemListMergeType,
	/*
    This endpoint will always return an empty response
    and an error code if the update fails
  */
	{}
>({
	url: ({ hybrisStore, user, cartId, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/carts/${cartId}/saveditemlists/copy?rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getSavedItemLists.clearCache();
			getSelectedItemListEntries.clearCache();
			getUserSavedItemLists.clearCache();
			getCartSubTotal.clearCache();
		},
	}),
});

/**
 * Move Entries to Saved Item List
 * https://wiki.namics.com/display/DOKA/03+-+OCC+Security+Settings
 */
export const moveItemToSavedItemList = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		cartId: string;
		rootB2BUnit: string;
	},
	SavedItemListMergeType,
	/*
    This endpoint will always return an empty response
    and an error code if the update fails
  */
	{}
>({
	url: ({ hybrisStore, user, cartId, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/carts/${cartId}/saveditemlists/move?rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getSavedItemLists.clearCache();
			getSelectedItemListEntries.clearCache();
			getUserSavedItemLists.clearCache();
			getCartSubTotal.clearCache();
		},
	}),
});

/**
 * Delete Entries to Saved Item List
 * https://wiki.namics.com/display/DOKA/03+-+OCC+Security+Settings
 */
export const deleteSavedItemList = DeleteEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		savedItemListCode: string;
		rootB2BUnit: string;
		shouldReloadWishlist?: boolean;
	},
	/*
    This endpoint will always return an empty response
    and an error code if the update fails
  */
	{}
>({
	url: ({ hybrisStore, user, savedItemListCode, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/saveditemlists/${savedItemListCode}?rootB2BUnit=${rootB2BUnit}`,
	options: ({ token, shouldReloadWishlist = true }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			if (shouldReloadWishlist) {
				getSavedItemLists.clearCache();
			}
			getSelectedItemListEntries.clearCache();
			getUserSavedItemLists.clearCache();
		},
	}),
});

/**
 * Delete Item from Saved Item List
 * https://wiki.namics.com/display/DOKA/03+-+OCC+Security+Settings
 */
export const deleteItemListEntries = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	{
		code: string;
		description?: string;
		entryNumbers?: Array<number>;
		headPositions?: Array<string>;
		name?: string;
	},
	/*
    This endpoint will always return an empty response
    and an error code if the update fails
  */
	{}
>({
	url: ({ hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/saveditemlists/entries?rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getSavedItemLists.clearCache();
			getSelectedItemListEntries.clearCache();
			getUserSavedItemLists.clearCache();
		},
	}),
});

/**
 * Move Entries to Saved Item List
 * https://wiki.namics.com/display/DOKA/03+-+OCC+Security+Settings
 */
export const addToConfigurationSavedItemList = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		savedItemListCode: string;
		productCode: string;
		qty: number;
		configId: string;
		rootB2BUnit: string;
	},
	{},
	void
>({
	url: ({ hybrisStore, user, savedItemListCode, productCode, qty, configId, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/saveditemlists/${savedItemListCode}/entries?productCode=${productCode}&qty=${qty}&configId=${configId}&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getSavedItemLists.clearCache();
			getSelectedItemListEntries.clearCache();
			getUserSavedItemLists.clearCache();
		},
	}),
});

/**
 * Return all saved Item List for the given token with result
 */
export const getSavedItemLists = GetEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		page: number;
		rootB2BUnit: string;
	},
	SearchPageSavedItemListType
>({
	url: ({ user, hybrisStore, page, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/saveditemlists/?pagination=true&page=${page}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Return saved Item List exceeding B2B User Limit
 */
export const getSavedItemListsToRemove = GetEndpoint.create<
	{
		language: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		accessType: string;
	},
	SavedListToDelete
>({
	url: ({ language, user, hybrisStore, rootB2BUnit , accessType}) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/saveditemlists/listToRemove?lang=${language}&zkPartner=&rootB2BUnit=${rootB2BUnit}&accessType=${accessType}`,
	cacheEnabled: false,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Update an existing NewSeries Entries in Saved Item List (using the headPositionId)
 *
 * @see https://wiki.namics.com/pages/viewpage.action?pageId=628330561
 */
export const saveNewSeriesEntriesInSavedItemList = PostEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		savedItemListCode: string;
		/* Bearer Token */
		token: string;
		/* HeadPosition id - an id for a group of prodcuts */
		headPosition: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		createNew: boolean;
	},
	NewSeriesBackendType,
	AddToCartResponse
>({
	url: ({ headPosition, savedItemListCode, language, hybrisStore, user, rootB2BUnit, createNew }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/saveditemlist/${savedItemListCode}/newseries/entries/${headPosition}?lang=${language}&zkPartner=&rootB2BUnit=${rootB2BUnit}&createNew=${createNew}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getNewSeriesEntriesByHeadPosition.clearCache();
			getSavedItemLists.clearCache();
			getSelectedItemListEntries.clearCache();
			getUserSavedItemLists.clearCache();
		},
	}),
});

/**
 * Save the given reorder entries into an existing head position in Saved Item List
 * https://wiki.namics.com/pages/viewpage.action?pageId=627740333
 */
export const addOrUpdateHeadPositionToSavedItemList = PostEndpoint.create<
	{
		language: string;
		savedItemListCode: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	BackendCartStructureType,
	AddToCartResponse
>({
	url: ({ savedItemListCode, language, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/saveditemlist/${savedItemListCode}/headposition/addToItemlist?lang=${language}&zkPartner=&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getSavedItemLists.clearCache();
			getSelectedItemListEntries.clearCache();
			getUserSavedItemLists.clearCache();
		},
	}),
});
