import { GetEndpoint } from '../../src/lib/ajax/getEndpoint';
import { CountryType, LanguageType, RegionType, TitleType, ValidationRulesType } from '../types/addressOptionsController';

/**
 * Return all delivery countries
 * https://i-14.my.dormakaba.com/dormakabacommercewebservices/swagger/swagger-ui.html#!/miscs-controller/getDeliveryCountriesUsingGET
 */
export const getDeliveryCountries = GetEndpoint.create<
	{
		language: string;
		token: string;
		hybrisStore: string;
		rootB2BUnit?: string;
		fields?: 'DEFAULT' | 'FULL';
	},
	{
		countries: Array<CountryType>;
		addressCountries?: Array<CountryType>;
	}
>({
	url: ({ language, hybrisStore, rootB2BUnit, fields }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/deliverycountries?lang=${language}&rootB2BUnit=${rootB2BUnit}${fields ? '&fields=' + fields : ''}`,
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

/**
 * Return all languages
 * https://i-14.my.dormakaba.com/dormakabacommercewebservices/swagger/swagger-ui.html#!/miscs-controller/getLanguagesUsingGET
 */
export const getLanguages = GetEndpoint.create<
	{
		language: string;
		token: string;
		hybrisStore: string;
		rootB2BUnit?: string;
	},
	{
		languages: Array<LanguageType>;
	}
>({
	url: ({ language, hybrisStore, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/languages?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

export const getRegions = GetEndpoint.create<
	{
		language: string;
		token: string;
		hybrisStore: string;
		rootB2BUnit?: string;
		countryCode?: string;
		currentUserId?: string;
	},
	RegionType[]
>({
	url: ({ countryCode, currentUserId, language, hybrisStore, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/users/${currentUserId}/checkout/${countryCode}/regions?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

export const getValidationRules = GetEndpoint.create<
	{
		language: string;
		token: string;
		hybrisStore: string;
		rootB2BUnit?: string;
		countryCode?: string;
		currentUserId?: string;
	},
	ValidationRulesType
>({
	url: ({ countryCode = '', currentUserId, language, hybrisStore, rootB2BUnit }) => 
		`/dormakabacommercewebservices/v2/${hybrisStore}/users/${currentUserId}/checkout/validationRules?rootB2BUnit=${rootB2BUnit}&lang=${language}&isoCode=${countryCode}`,
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

/**
 * Return all titles for salutation
 * https://i-14.my.dormakaba.com/dormakabacommercewebservices/swagger/swagger-ui.html#!/miscs-controller/getTitlesUsingGET
 */
export const getTitles = GetEndpoint.create<
	{
		language: string;
		token: string;
		hybrisStore: string;
		rootB2BUnit?: string;
	},
	{
		titles: Array<TitleType>;
	}
>({
	url: ({ language, hybrisStore, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/titles?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});
