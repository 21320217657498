import { PostEndpoint } from '../../src/lib/ajax/postEndpoint';
import { ProductSearchFilterType, ProductSearchRequestType, ProductSearchResultType } from '../types/emksProductSearch';

// https://wiki.namics.com/pages/viewpage.action?title=10+-+OCC+Product&spaceKey=DOKA
export const getProducts = PostEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		/* Bearer Token */
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	ProductSearchRequestType,
	ProductSearchResultType
>({
	url: ({ language, hybrisStore, user, rootB2BUnit }) => {
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/products?lang=${language}&zkPartner=&rootB2BUnit=${rootB2BUnit}`;
	},
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

export function createProductTypeFilter(productType: string) {
	return createSearchFilter('product_type_string', [productType]);
}

export function createMaterialFilter(productMaterial: string) {
	return createSearchFilter('materialType_string', [productMaterial]);
}

function createSearchFilter(key: string, values: string[]): ProductSearchFilterType {
	return {
		key: key,
		values: values,
	};
}
