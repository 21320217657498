import { GetEndpoint } from '../../src/lib/ajax/getEndpoint';
import { PostEndpoint } from '../../src/lib/ajax/postEndpoint';
import { Permission } from '../types/emksAuthentication';
import { KeySystem, SecurityStatus } from '../types/emksKeySystemController';
import { SecuritySettingRequest, SecuritySettingResponse } from '../types/keySystemData/securitySettings';
import { EmksAddress } from '../types/keySystemData/personalData';

// The following endpoints can be tested using swagger:
// http://dev-storybook-dormakaba.namics-test.com/dormakabacommercewebservices/swagger/swagger-ui.html

/**
 * Return all connected keySystemCode for the given token
 */
export const getKeySystemHistoryEntries = GetEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		historyEntryListSize: number;
	},
	{
		keySystemHistoryEnries: Array<{
			code: string;
			lastUsedDate: Date;
			permissions: Array<Permission>;
		}>;
	}
>({
	url: ({ hybrisStore, user, rootB2BUnit, historyEntryListSize }) => {
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/?rootB2BUnit=${rootB2BUnit}&historyEntryListSize=${historyEntryListSize}`;
	},
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Returns content of key system for head position
 */
export const getKeySystem = GetEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		keySystemCode: string;
		rootB2BUnit: string;
	},
	KeySystem
>({
	url: ({ hybrisStore, user, keySystemCode, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/${keySystemCode}/headposition?rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Returns content of key system with master data for head position
 */
export const getOrderItems = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		language: string;
		headPosition: string;
		cartId: string;
		rootB2BUnit: string;
		requestedItemType: string;
		currentPage?: number;
		showMyOrders?: boolean;
		showInconsistencies?: boolean;
	},
	Record<string, any>,
	KeySystem
>({
	url: ({
		hybrisStore,
		user,
		language,
		headPosition,
		rootB2BUnit,
		requestedItemType,
		currentPage,
		showMyOrders,
		showInconsistencies,
	}) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/headposition/${headPosition}/entries?lang=${language}&rootB2BUnit=${rootB2BUnit}&requestedItemType=${requestedItemType}&page=${currentPage}&showMyOrders=${showMyOrders}&showInconsistencies=${showInconsistencies}`,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Retrieves the security settings for a given keySystemCode
 * https://wiki.namics.com/display/DOKA/03+-+OCC+Security+Settings
 */
export const getSecuritySettings = GetEndpoint.create<
	{
		keySystemCode: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	SecuritySettingResponse
>({
	url: ({ keySystemCode, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/${keySystemCode}/securitysettings?rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

/**
 * Persist the security settings for a given keySystemCode
 * https://wiki.namics.com/display/DOKA/03+-+OCC+Security+Settings
 */
export const updateSecuritySettings = PostEndpoint.create<
	{
		keySystemCode: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	SecuritySettingRequest,
	/*
    This endpoint will always return an empty response
    and an error code if the update fails
  */
	void
>({
	url: ({ keySystemCode, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/${keySystemCode}/securitysettings?rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getSecuritySettings.clearCache();
			getSecurityStatus.clearCache();
		},
	}),
});

/**
 * Return all partners for the given keySystemCode
 * https://wiki.namics.com/pages/viewpage.action?pageId=626897865
 */
export const getPartners = GetEndpoint.create<
	{
		keySystemCode: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	{
		currentPartner: EmksAddress;
	}
>({
	url: ({ keySystemCode, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/${keySystemCode}/partners?rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

/**
 *  Get the Security Status of particular KeySystem Code
 */
export const getSecurityStatus = GetEndpoint.create<
	{
		language: string;
		keySystemCode: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		currentTimestamp: number;
	},
	SecurityStatus
>({
	url: ({keySystemCode, hybrisStore, user, language, rootB2BUnit, currentTimestamp}) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/${keySystemCode}/security-status?lang=${language}&rootB2BUnit=${rootB2BUnit}&currentTimestamp=${currentTimestamp}`,
	cacheEnabled: false,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});
