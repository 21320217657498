import {DownloadEndpoint} from '../../src/lib/ajax/downloadEndpoint';
import {GetEndpoint} from '../../src/lib/ajax/getEndpoint';
import {DownloadOptions, DownloadStatus, DownloadType} from '../types/emksFileController';
import {EmksCustomerSegment} from '../../../user-service';

// The following endpoints can be tested using swagger:
// http://dev-storybook-dormakaba.namics-test.com/dormakabacommercewebservices/swagger/swagger-ui.html
// https://wiki.namics.com/pages/viewpage.action?pageId=613780327F

export const startKeySystemDownload = GetEndpoint.create<
	{
		language: string;
		keySystemCode: string;
		optionId: string;
		subOptions?: Array<string>; // Array of subOption ids
		token: string;
		hybrisStore: string;
		user: string;
		signal?: AbortSignal;
		rootB2BUnit: string;
		additionalInputContent?: string;
		customerSegment: EmksCustomerSegment;
		headPositionCode?: string;
	},
	DownloadStatus
>({
	url: ({
			  optionId,
			  subOptions,
			  keySystemCode,
			  language,
			  hybrisStore,
			  user,
			  rootB2BUnit,
			  additionalInputContent,
			  customerSegment,
			  headPositionCode,
		  }) => {
		const subOptionParams =
			subOptions && subOptions.length > 0
				? '&' +
				  subOptions
						.map(sub => {
							return `subOption=${encodeURIComponent(sub)}`;
						})
						.join('&')
				: '';
		const inputVal = additionalInputContent ? `&additionalInputContent=${additionalInputContent}` : '';
		const headPosition = headPositionCode ? `&headPositionCode=${headPositionCode}` : '';
		return `/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/keysystems/${keySystemCode}/file/generate?option=${optionId}${subOptionParams}&lang=${language}&rootB2BUnit=${rootB2BUnit}${inputVal}&customerSegment=${customerSegment}${headPosition}`;
	},
	cacheEnabled: false,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

// https://wiki.namics.com/pages/viewpage.action?pageId=613780327
export const getKeySystemDownloadStatus = GetEndpoint.create<
	{
		language: string;
		keySystemCode: string;
		fileReference: string;
		token: string;
		hybrisStore: string;
		user: string;
		signal?: AbortSignal;
		rootB2BUnit: string;
		headPositionCode?: string;
	},
	DownloadStatus
>({
	url: ({ fileReference, keySystemCode, language, hybrisStore, user, rootB2BUnit, headPositionCode }) => {
		const headPosition = headPositionCode ? `&headPositionCode=${headPositionCode}` : '';
		return `/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/keysystems/${keySystemCode}/file/status?fileReference=${fileReference}&lang=${language}&rootB2BUnit=${rootB2BUnit}${headPosition}`;
	},
	cacheEnabled: false,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

// https://wiki.namics.com/pages/viewpage.action?pageId=620233809
export const downloadKeySystem = DownloadEndpoint.create<
	{
		language: string;
		keySystemCode: string;
		fileReference: string;
		fileName: string;
		mimeType?: string;
		token: string;
		hybrisStore: string;
		user: string;
		signal?: AbortSignal;
		rootB2BUnit: string;
		headPositionCode?: string;
	},
	void
>({
	url: ({ fileReference, keySystemCode, language, hybrisStore, user, rootB2BUnit, headPositionCode }) => {
		const headPosition = headPositionCode ? `&headPositionCode=${headPositionCode}` : '';
		return `/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/keysystems/${keySystemCode}/file/download?fileReference=${fileReference}&lang=${language}&rootB2BUnit=${rootB2BUnit}${headPosition}&ZkPartner=`;
	},
	useFileNameFromResponse: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

// https://wiki.namics.com/display/DOKA/08+-+OCC+Download+Options
export const getKeySystemDownloadOptions = GetEndpoint.create<
	{
		language: string;
		token: string;
		hybrisStore: string;
		keySystemCode: string;
		user: string;
		rootB2BUnit: string;
		customerSegment: EmksCustomerSegment;
		headPositionCode?: string;
		isPartnerDownloadArea?: boolean;
	},
	DownloadOptions
>({
	url: ({ keySystemCode, language, hybrisStore, user, rootB2BUnit, customerSegment, headPositionCode, isPartnerDownloadArea }) => {
		const headPosition = headPositionCode ? `&headPositionCode=${headPositionCode}` : '';
		const isPartnerDownload = isPartnerDownloadArea ? `&isPartnerDownloadArea=${isPartnerDownloadArea}`: '';
		return `/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/keysystems/${keySystemCode}/file/options?zkPartner=&lang=${language}&rootB2BUnit=${rootB2BUnit}&customerSegment=${customerSegment}${headPosition}${isPartnerDownload}`;
	},
	cacheEnabled: false,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

// https://wiki.namics.com/display/DOKA/07+-+OCC+Download+Keysystem
export const getKeySystemDownloadHistory = GetEndpoint.create<
	{
		language: string;
		token: string;
		hybrisStore: string;
		keySystemCode: string;
		user: string;
		rootB2BUnit: string;
		headPositionCode?: string;
	},
	{ downloads: Array<DownloadType> }
>({
	url: ({ keySystemCode, language, hybrisStore, user, rootB2BUnit, headPositionCode }) => {
		const headPosition = headPositionCode ? `&headPositionCode=${headPositionCode}` : '';
		return `/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/keysystems/${keySystemCode}/file/history?zkPartner=&lang=${language}&rootB2BUnit=${rootB2BUnit}${headPosition}`;
	},
	cacheEnabled: false,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

export const getDownloadHistory = GetEndpoint.create<
	{
		language: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	{ downloads: Array<DownloadType> }
>({
	url: ({ language, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/keysystems/file/history?zkPartner=&lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: false,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});
