import { GetEndpoint } from '../../src/lib/ajax/getEndpoint';

// https://wiki.namics.com/pages/viewpage.action?pageId=613092369
export const getToken = GetEndpoint.create<
	{
		encodedContextPath: string;
	},
	string
>({
	url: ({ encodedContextPath }) => `${encodedContextPath}/login/token`,
	// The request is only used by the user service
	// which has it's own cache
	cacheEnabled: false,
});
