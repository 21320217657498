import { Filter, Order } from '../types/orderHistory';
import { GetEndpoint } from '../../src/lib/ajax/getEndpoint';

/**
 * Return orders for the given search filter
 */
export const getOrderHistory = GetEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		filter: Filter;
		rootB2BUnit: string;
		language: string;
	},
	{ orders: Array<Order> }
>({
	url: ({ user, hybrisStore, filter, rootB2BUnit , language}) => {
		if (filter.filterType === 'time') {
			return `/dormakabacommercewebservices/v2/${hybrisStore}/users/${user}/orders/byDate?rootB2BUnit=${rootB2BUnit}&lang=${language}&orderedFrom=${filter.filterFrom.toISOString()}&orderedTo=${filter.filterTo.toISOString()}`;
		}
		if (filter.filterType === 'number') {
			return `/dormakabacommercewebservices/v2/${hybrisStore}/users/${user}/orders/byOrderNumber?rootB2BUnit=${rootB2BUnit}&lang=${language}&orderNumber=${
				filter.orderNumber ? filter.orderNumber : ''
			}&customerReferenceNumber=${filter.customerReferenceNumber ? filter.customerReferenceNumber : ''}`;
		}
		throw new Error(
			"Can't handle unknown order history filter type [" + filter.filterType ? filter.filterType : 'null' + ']'
		);
	},
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});
